<template>
	<UmbracoImage v-bind="attrs" class="c-umbraco-image-ext" />
</template>

<script>
import UmbracoImage from '@limbo-works/vue-umbraco-image';

export default {
	name: 'UmbracoImageExt',
	components: { UmbracoImage },

	props: {
		...UmbracoImage.props,

		sizes: {
			type: String,
			default: 'auto',
		},

		aspectRatio: {
			type: Number,
			required: false,
		},

		srcWidth: {
			type: Number,
			required: false,
		},

		alt: {
			type: String,
			required: false,
		},
	},

	computed: {
		attrs() {
			const { alt, sourceWidth, sourceHeight, aspectRatio } = this;
			const ratio = aspectRatio || sourceWidth / sourceHeight;

			const formats = {
				// webp: 'image/webp',
				jpeg: 'image/jpeg',
			};
			if (this.sourceUrl?.split?.('?')?.[0]?.endsWith?.('.png')) {
				formats.png = 'image/png';
				delete formats.jpeg;
			}

			return {
				...this.$props,
				alt,

				lazyload: true,
				aspectRatio: ratio,
				srcWidth: this.srcWidth || this.widths[0],
				style: `aspect-ratio: ${ratio}`,
				formats,
			};
		},
	},
};
</script>

<style>
.c-umbraco-image-ext {
	@apply bg-primary block;

	position: relative;
}

.c-umbraco-image-ext .c-umbraco-image__image {
	@apply opacity-0 duration-500 ease-smooth-out;
	@apply absolute w-full h-full top-0 left-0;
}

.c-umbraco-image-ext .c-umbraco-image__image.is-lazyloaded {
	@apply opacity-100;
}
</style>
