<!--
	Last modified: 2022/06/15 10:10:18
-->
<script>
let hasWarnedAboutMissingA11yLabel = false;

export default {
	name: 'LinkTile',

	functional: true,

	props: {
		// The tag of the outer/wrapping element
		tag: {
			type: String,
			default: 'div',
		},

		// For using other kinds of links (RouterLink/NuxtLink/etc.)
		linkTag: {
			type: String,
			default: 'NuxtLinkExt',
		},

		// This is to pinpoint specific elements to treat as the link instead of the whole tile
		linkPartialsQuery: {
			type: String,
			default: undefined,
		},

		// This should allow us to click on other buttons, links, etc. "inside the link"
		clickableElementsQuery: {
			type: String,
			default:
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
		},

		// For when using RouterLink/NuxtLink/etc.
		to: {
			type: [String, Object],
			default: undefined,
		},

		// A slew of normal attributes that can be passed to the link
		id: {
			type: String,
			default: undefined,
		},

		href: {
			type: [String, Object],
			default: undefined,
		},

		target: {
			type: String,
			default: undefined,
		},

		title: {
			type: String,
			default: undefined,
		},

		tabindex: {
			type: [String, Number],
			default: undefined,
		},

		download: {
			type: String,
			default: undefined,
		},

		hreflang: {
			type: String,
			default: undefined,
		},

		ping: {
			type: String,
			default: undefined,
		},

		referrerpolicy: {
			type: String,
			default: undefined,
		},

		rel: {
			type: String,
			default: undefined,
		},

		type: {
			type: String,
			default: undefined,
		},

		// A bunch more attributes more closely tied to a11y
		// a11y: based off of https://www.w3.org/TR/wai-aria-1.1/#link-0
		role: {
			type: String,
			default: undefined,
		},

		ariaRoledescription: {
			type: String,
			default: undefined,
		},

		ariaLabel: {
			type: String,
			default: undefined,
		},

		ariaLabelledby: {
			type: String,
			default: undefined,
		},

		ariaDetails: {
			type: String,
			default: undefined,
		},

		ariaDescribedby: {
			type: String,
			default: undefined,
		},

		ariaControls: {
			type: String,
			default: undefined,
		},

		ariaCurrent: {
			type: String,
			default: undefined,
		},

		ariaDisabled: {
			type: String,
			default: undefined,
		},

		ariaFlowto: {
			type: String,
			default: undefined,
		},

		ariaHaspopup: {
			type: String,
			default: undefined,
		},

		ariaKeyshortcuts: {
			type: String,
			default: undefined,
		},

		ariaLive: {
			type: String,
			default: undefined,
		},

		ariaOwns: {
			type: String,
			default: undefined,
		},
	},

	render(h, ctx) {
		const { props, data, scopedSlots } = ctx;

		// Make link element
		const linkBindings = {
			attrs: {
				id: props.id,
				class: 'c-link-tile__link',
				href: props.href,
				to: props.to,
				target: props.target,
				title: props.title,
				tabindex: props.tabindex,
				download: props.download,
				hreflang: props.hreflang,
				ping: props.ping,
				referrerpolicy: props.referrerpolicy,
				rel: props.rel,
				type: props.type,

				// a11y
				role: props.role,
				'aria-roledescription': props.ariaRoledescription,
				'aria-label': props.ariaLabel,
				'aria-labelledby': props.ariaLabelledby,
				'aria-details': props.ariaDetails,
				'aria-describedby': props.ariaDescribedby,
				'aria-controls': props.ariaControls,
				'aria-current': props.ariaCurrent,
				'aria-disabled': props.ariaDisabled,
				'aria-flowto': props.ariaFlowto,
				'aria-haspopup': props.ariaHaspopup,
				'aria-keyshortcuts': props.ariaKeyshortcuts,
				'aria-live': props.ariaLive,
				'aria-owns': props.ariaOwns,
			},
			on: {
				click: data?.on?.click || (() => {}),
			},
		};
		// Remove unused attrs to avoid unintentional issues
		for (const attr in linkBindings.attrs) {
			if (linkBindings.attrs[attr] === undefined) {
				delete linkBindings.attrs[attr];
			}
		}

		const linkElement = (
			<props.linkTag key="link" {...linkBindings}></props.linkTag>
		);
		const hoverData = {
			linkVNode: linkElement,
			isHovering: false,
		};

		// Create final component
		const bindings = {
			...data,
			staticClass: null,
			class: ['c-link-tile', data?.staticClass, data?.class],
			props: null,
			on: {
				...(data?.on || {}),

				click: (e) => {
					const el = linkElement?.elm?.parentElement;

					// Cancel if the actual link is targeted to avoid infinite recursion
					if (e.target?.classList?.contains('c-link-tile__link')) {
						return;
					}

					// Cancel if an inner button is targeted
					if (
						props.clickableElementsQuery &&
						[
							...el.querySelectorAll(
								props.clickableElementsQuery
							),
						].includes(e.target)
					) {
						return;
					}

					// Cancel if element should not be treated as a link
					if (props.linkPartialsQuery) {
						const linkPartials = [
							...el.querySelectorAll(props.linkPartialsQuery),
						];
						if (linkPartials.length === 0) {
							return;
						}
						if (!linkPartials.includes(e.target)) {
							let isPartial = false;
							linkPartials.forEach((partial) => {
								isPartial = getPath(e).includes(partial)
									? true
									: isPartial;
							});

							if (!isPartial) {
								return;
							}
						}
					}

					// Click on link - doing it this way, we pass on shift/ctrl/etc. modifiers
					const event = new MouseEvent('click', e);
					linkElement?.elm?.dispatchEvent?.(event);

					// Cancel/stop everything just to be sure
					e.preventDefault();
					e.stopPropagation();
				},
				mouseup: (e) => {
					data?.on?.mouseup?.(e); // Run the usual event, if such is defined

					const el = linkElement?.elm?.parentElement;

					// Cancel if the actual link is targeted to avoid infinite recursion
					if (e.target?.classList?.contains('c-link-tile__link')) {
						return;
					}

					// Cancel if an inner button is targeted
					if (
						props.clickableElementsQuery &&
						[
							...el.querySelectorAll(
								props.clickableElementsQuery
							),
						].includes(e.target)
					) {
						return;
					}

					// Cancel if element should not be treated as a link
					if (props.linkPartialsQuery) {
						const linkPartials = [
							...el.querySelectorAll(props.linkPartialsQuery),
						];
						if (linkPartials.length === 0) {
							return;
						}
						if (!linkPartials.includes(e.target)) {
							let isPartial = false;
							linkPartials.forEach((partial) => {
								isPartial = getPath(e).includes(partial)
									? true
									: isPartial;
							});

							if (!isPartial) {
								return;
							}
						}
					}

					// Make context menu available when mouseup on right button
					if (linkElement?.elm && e.button === 2) {
						linkElement.elm.style.pointerEvents = 'auto';
						window.requestAnimationFrame(() => {
							linkElement.elm.style.pointerEvents = null;
						});
					}
				},

				mouseover(e) {
					data?.on?.mouseover?.(e);
					if (e.defaultPrevented) {
						return;
					}

					const el = linkElement?.elm?.parentElement;
					if (el) {
						// Cancel if an inner button is targeted
						if (
							props.clickableElementsQuery &&
							[
								...el.querySelectorAll(
									props.clickableElementsQuery
								),
							].includes(e.target)
						) {
							el.removeAttribute('data-hover');
							if (hoverData.isHovering) {
								hoverData.isHovering = false;
								data?.on?.hoverupdate?.({ ...hoverData });
								data?.on?.hoverend?.({ ...hoverData });
							}
							return;
						}

						// Cancel if element should not be treated as a link
						if (props.linkPartialsQuery) {
							const linkPartials = [
								...el.querySelectorAll(props.linkPartialsQuery),
							];
							if (linkPartials.length === 0) {
								el.removeAttribute('data-hover');
								if (hoverData.isHovering) {
									hoverData.isHovering = false;
									data?.on?.hoverupdate?.({ ...hoverData });
									data?.on?.hoverend?.({ ...hoverData });
								}
								return;
							}
							if (!linkPartials.includes(e.target)) {
								let isPartial = false;
								linkPartials.forEach((partial) => {
									isPartial = getPath(e).includes(partial)
										? true
										: isPartial;
								});

								if (!isPartial) {
									el.removeAttribute('data-hover');
									if (hoverData.isHovering) {
										hoverData.isHovering = false;
										data?.on?.hoverupdate?.({
											...hoverData,
										});
										data?.on?.hoverend?.({ ...hoverData });
									}
									return;
								}
							}
						}

						el.setAttribute('data-hover', 'hover');
						if (!hoverData.isHovering) {
							hoverData.isHovering = true;
							data?.on?.hoverstart?.({ ...hoverData });
							data?.on?.hoverupdate?.({ ...hoverData });
						}
					}
				},
				mouseleave(e) {
					data?.on?.mouseleave?.(e);
					if (e.defaultPrevented) {
						return;
					}

					const el = linkElement?.elm?.parentElement;
					if (el) {
						el.removeAttribute('data-hover');
						hoverData.isHovering = false;
						data?.on?.hoverupdate?.({ ...hoverData });
						data?.on?.hoverend?.({ ...hoverData });
					}
				},
			},
		};

		const defaultSlot = scopedSlots?.default;
		const element = (
			<props.tag key="wrapper" {...bindings}>
				{linkElement}
				{defaultSlot?.()}
			</props.tag>
		);

		// And finally, some extra nice: A warning of missing a11y attributes if needed
		if (
			!hasWarnedAboutMissingA11yLabel &&
			!props.ariaLabel &&
			!props.ariaLabelledby
		) {
			hasWarnedAboutMissingA11yLabel = true;
			console.warn(
				'[LinkTile]',
				"No a11y label attributes were provided. This may cause accessibility issues. Add either the 'aria-label' or 'aria-labelledby' attribute to the component, to avoid any issues."
			);
		}

		// Return the element
		return element;
	},
};

// Helper functions
function getPath(event, _element = null, _path = null) {
	const path = _path || event.path || [];
	let element = _element || event.srcElement;

	if (!event.path && element.parentNode) {
		path.push(element);
		element = element.parentNode;
		return getPath(event, element, path);
	}

	return path;
}
</script>

<style>
:where(.c-link-tile) {
	position: relative;
	display: inline-block;
}

:where(.c-link-tile__link) {
	position: absolute;
	z-index: 99999;
	display: block;
	pointer-events: none;
	inset: 0;
}

:where(.c-link-tile[data-hover='hover']) {
	cursor: pointer;
}
</style>
